import React from 'react';

export default function VersionTile() {

  return (
    <div>
        Version : 1.0.0.2
    </div>
  );
}
