import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export default function NewAirportTile() {
  const history = useHistory();

  const route = {
    pathname: '/airport',
    search: '?id=ewrAirport',
  }

  const handleClick = (event) => {
    event.preventDefault();
    history.push(route);
  }

  return (
    <div role='button' onClick={handleClick} className='o-airport-link-button l-position__relative c-service-item-link'>
      
      <span
        className='o-new-airport-banner-legend'
      ><Trans i18nKey='' /></span>
    </div>
  );
}
