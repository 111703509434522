import React from 'react';
import ButtonGrid from '../../components/buttonGrid';
import NewAirportTile from '../../components/customTiles/newAirportTile';
import { COLORS } from '../../constants/appConstants';
import VersionTile from '../../components/customTiles/versionTile';

export default function Home() {

  return (
    <div className="l-page-content__standard">
      <ButtonGrid links={[
        {
          titleKey: 'transit',
          to: '/transit',
          svgName: 'tile-transit',
          colorStyle: 'background-color-green',
        },
        {
          titleKey: 'Stay',
          to: '/stayTile',
          svgName: 'tile-stay',
          colorStyle: 'background-color-red',
        },
        {
          titleKey: 'visit',
          to: '/visit',
          svgName: 'tile-visit',
          colorStyle: 'background-color-sky-blue',
        },
        {
          titleKey: 'information',
          to: '/information',
          svgName: 'tile-info',
          svgFill: COLORS.BLACK,
          colorStyle: 'background-color-yellow',
        },
      ]} />
      <NewAirportTile />
      <VersionTile />
    </div>
  );
}
