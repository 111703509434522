export default {
  translations: {
    airport: 'Airport',
    home: 'Home',
    transit: 'Transit',
    stay: 'Stay',
    visit: 'Visit',
    information: 'Info',
    placesToGo: 'Places To Go',
    thingsToDo: 'Things To Do',
    events: 'Events',
    planYourTrip: 'Plan Your Trip',
    letUsKnowHow: 'Let us know how we can improve your visit.',
    back: 'Back',
    provideFeedback: 'Provide Feedback',
    welcomeToNewYork: 'Welcome to New Jersey',
    learnMore: 'Learn More',
    dateFormat: '{{dateString}}. {{timeString}}',
    publicTransportation: 'Public Transportation',
    onAirportBus: 'On Airport Bus',
    airportShuttle: 'Airport Shuttle',
    bookRide: 'Book Ride',
    reviewReservation: 'Review Reservation',
    rentalCars: 'Rental Cars',
    carServices: 'Car Services',
    taxis: 'Taxis',
    feedback: 'Let us know how we can improve your visit.',
    welcomeToNewYorkWithPeriod: 'Welcome to New Jersey.',
    dateStatus: 'Today is <1>{{dateStr}}</1>.  It is <3>{{timeStr}}</3>.',
    dateWeatherStatus: 'Today is <1>{{dateStr}}</1>.  It is <3>{{timeStr}}</3> and it\'s <5 /><6> {{temperatureStr}}</6> outside.',
    piiDisclaimer: 'This kiosk does not connect, track, or retain any personally identifiable information.',
    piiWillBeDestroyed: 'All personal information will be destroyed at the end of your session.',
    rentalCarServicesHeader: 'Rental Car Shuttle Pick-Up and Drop-Off',
    rentalCarServicesDescription: 'All Rental Car Shuttles pick-up and drop-off customers in front of Terminal A (Marine Air Terminal) in the Rental Car Shuttle Transfer Point. Travelers can take the free airport shuttle Blue or Red Route to Terminal A (Marine Air Terminal) to connect with shuttle buses for Advantage, Alamo, Avis, Budget, Dollar, Enterprise, Hertz, National, Payless and Thrifty car rental facilities.',
    rentalCarServicesContactDescription: 'Contact your rental car company for more information on their shuttle service.',
    rentalCarCompanies: 'Rental Car Companies',
    rentalCarCompaniesAdvantage: 'Advantage',
    rentalCarCompaniesAvis: 'Avis',
    rentalCarCompaniesDollar: 'Dollar',
    rentalCarCompaniesHertz: 'Hertz',
    rentalCarCompaniesPayless: 'Payless',
    rentalCarCompaniesAlamo: 'Alamo',
    rentalCarCompaniesBudget: 'Budget',
    rentalCarCompaniesEnterprise: 'Enterprise',
    rentalCarCompaniesNational: 'National',
    rentalCarCompaniesThrifty: 'Thrifty',
    shuttleRoutes: 'Shuttle Routes',
    shuttleRoutesTerminalA: 'Getting to Terminal A and Rental Car Shuttle Transfer Point',
    shuttleRoutesTerminalB: 'From Terminal B take the airport shuttle Blue Route located on the Departures (upper) Level.',
    taxiServicesHeader: 'Taxi Cabs',
    taxiServicesDescription: 'Metered taxi service is available at all LaGuardia Airport terminal buildings.',
    safetyTip: 'SAFETY TIP',
    taxiServicesSafetyTipDescription: 'Soliciting ground transportation is illegal and solicitors can be unlicensed and uninsured. If you’re unsure about a taxi or car service, please visit the Port Authority Welcome Center located in the arrivals area of each terminal, where uniformed staff will be happy to assist you.',
    taxiServicesInformation: 'Here is some helpful information when taking a taxi from LaGuardia Airport',
    taxiServicesInformationFirstBullet: 'There is a $1.00 peak-time surcharge for trips from 4 p.m. to 8 p.m. on weekdays',
    taxiServicesInformationSecondBullet: 'There is a $.50 surcharge for trips from 8 p.m. to 6 a.m. daily. There is also a NY State tax of $.50 added to trips within New York, but not for trips to New Jersey.',
    taxiServicesInformationThirdBullet: 'One fare pays for all passengers to one destination',
    taxiServicesInformationFourthBullet: 'Four passengers (five in minivans) is the limit for New York City cabs',
    taxiServicesInformationFifthBullet: 'Meter must read $3.00 at the start of the trip',
    taxiServicesInformationSixthBullet: 'Taxis are regulated by the NYC Taxi and Limousine Commission. Please contact them with rate questions, compliments, complaints or lost property.',
    or: 'or',
    airportMap: 'Airport Map',
    carServicesDescription: 'A number of car, van and shared ride services are available at LaGuardia Airport.',
    sharedRideServices: 'Shared Ride Services',
    carServicesSharedRideServicesDescription: 'Reservation for a Share Ride van service can be made using the self-service phones or <1>online here</1>.',
    expressBusServices: 'Express Bus Services',
    carServicesExpressBusServicesDescription: 'Reservations for Express Bus Service can be made using the Self-Service phones.',
    carServicesTerminalBShareRide: 'Terminal B Share Ride Pick-Up',
    carServicesTerminalBShareRideDescription: 'After you have booked your ride with a Share Ride provider you will wait in the Welcome Center for the driver. Please contact your Share Ride provider directly for questions or updates on arrival.',
    carServicesTerminalBShareRideServicesDescription: 'Reservation with App-Based Services Providers including Uber, Lyft, Via and Gett are available through their mobile app.',
    privateCarServices: 'Private Car Services',
    privateCarServicesDescription: 'Reservation for a private car service can be made using the self-service phones.',
    carServicesTerminalBPrivateRide: 'Terminal B Private Car Service & App-Based Provider Pick-Up',
    carServicesTerminalBPrivateRideDescription: 'Private Car Service and App-Based provider pick-up area is located in Terminal B Parking Garage, Level 2. Follow signs to the garage for the pick-up area and contact your private car service/app-based provider when you reach the pick-up area.',
    sharedRideServicesAirLinkNewYork: 'Airlink New York',
    sharedRideServicesAllCountyExpress: 'All County Express',
    sharedRideServicesETSAirShuttle: 'ETS Air Shuttle',
    sharedRideServicesPrimeTimeShuttle: 'PrimeTimeShuttle',
    expressBusServicesNYCExpressBus: 'NYC Express Bus/ Golden Touch',
    privateCarServicesCarmelSuperSaver: 'Carmel Super Saver',
    flightTracker: 'Flight Tracker',
    lostAndFound: 'Lost And Found',
    airlineDirectory: 'Airline Directory',
    parking: 'Parking',
    shopsAndRestaurants: 'Shops and Restaurants',
    lostFoundLine1: 'If you have found a lost item, you can drop that item off with any airline or airport official.',
    lostFoundLine2: 'If you have lost an item please click on the link below and follow the instructions. If you want to check on the status of your lost item you can visit panynj.lostandfoundsite.com and click on the “Check Lost Item Status” link at the bottom. Thank you.',
    reportLostItem: 'Report Lost Item',
    shopping: 'Shopping',
    newarkvisitorcenter: 'Newark Visitor Guide'
  }
};
