export const Destinations = [
  {
    id: 'feedback',
    url: 'https://www.newarkairport.com/feedback/',
  },
  {
    id: 'publicTransportation',
    url: 'https://www.newarkairport.com/to-from-airport/public-transportation',
  },
  {
    id: 'onAirportBusServices',
    url: 'https://www.newarkairport.com/at-airport/on-airport-bus',
  },
  {
    id: 'bookRide',
    url: 'https://lga.gtisnext.com/kiosk',
  },
  {
    id: 'reviewReservation',
    url: 'https://lga.gtisnext.com/welcome',
  },
  {
    id: 'stay',
    url: 'https://www.booking.com/index.html?prefer_site_type=tdot',
  },
  {
    id: 'placesToGo',
    url: 'http://visitnj.org',
  },
  {
    id: 'thingsToDo',
    url: 'https://visitnj.org',
  },
  {
    id: 'events',
    url: 'https://visitnj.org/nj/events/',
  },
  {
    id: 'planYourTrip',
    url: 'https://visitnj.org/new-jersey-trip-ideas/',
  },
  {
    id: 'ewrAirport',
    url: 'https://ewrt1.parasyst.net/SpeedDial.html',
  },
  {
    id: 'flightTracker',
    url: 'https://www.newarkairport.com',
  },
  {
    id: 'lostAndFound',
    url: 'https://www.chargerback.com/ewr',
  },
  {
    id: 'airlineDirectory',
    url: 'https://www.newarkairport.com/flight/airlines',
  },
  {
    id: 'airportMap',
    url: 'https://www.newarkairport.com/at-airport/airport-maps',
  },
  {
    id: 'parking',
    url: 'https://www.newarkairport.com',
  },
  {
    id: 'shopsAndRestaurants',
    url: 'https://www.newarkairport.com/at-airport/shops-restaurants-and-services',
  },
  {
    id: 'taxiservice',
    url:'https://www.newarkairport.com/to-from-airport/taxi-car-and-van-service'
  },
  {
    id: 'rentalcars',
    url: 'https://www.newarkairport.com/to-from-airport/taxi-car-and-van-service',
  },
  {
    id: 'carservices',
    url: 'https://www.newarkairport.com/at-airport/accessibility-services'
  },
  {
    id: 'elizabeth_Layover',
    url: 'https://www.goelizabethnj.com/plan/itineraries/layover-survival-guide/'
  },
  {
    id: 'elizabeth_Things-to-do',
    url: 'https://www.goelizabethnj.com/things-to-do/'
  },
  {
    id: 'njNewarkVisitorGuide',
    url: 'https://www.newarkhappening.com/about/download-visitors-guide/thank-you/'
  },
  {
    id: 'elizabeth_shopping',
    url: 'https://www.goelizabethnj.com/things-to-do/shopping/'
  },
  {
    id: 'elizabeth_restaurants',
    url: 'https://www.goelizabethnj.com/restaurants/'
  }
]