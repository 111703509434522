import React from "react";
import ButtonGrid from '../components/buttonGridPopup';

export const StayTile = () => (
  <ButtonGrid
  links={[
    {
      address: 'https://visitnj.org/nj/hotels-lodging',
      titleKey: 'New Jersey',
      to: {
        pathname: '/stay/external',
        search: '?id=tester&ext=1',
      },
      svgName: 'tile-stay-elizabet-icon',
    },
    {
      address: 'https://www.goelizabethnj.com/hotels/',
      titleKey: 'Elizabeth New Jersey',
      to: {
        pathname: '/stay/external',
        search: '?id=stay',
      },
      svgName: 'tile-stay-elizabet-icon',
    },
  ]}
  buttonColorStyle='background-color-red'
/>
);


