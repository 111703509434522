import React from "react";
import ButtonGrid from '../components/buttonGridPopup';


export const NJVisit = () => (
    <ButtonGrid
    links={[
      {
        address: 'https://visitnj.org/nj/shopping',
        titleKey: 'Shopping',
        to: {
          pathname: '/visit/external',
          search: '?id=shopping&ext=1',
        },
        svgName: 'tile-shopping-bag-icon',
      },
      {
        address: 'https://visitnj.org/things-to-do',
        titleKey: 'Attractions',
        to: {
          pathname: '/visit/external',
          search: '?id=thingsToDo',
        },
        svgName: 'tile-things-to-do',
      },
      {
        address: 'https://visitnj.org/nj/events/',
        titleKey: 'events',
        to: {
          pathname: '/visit/external',
          search: '?id=events',
        },
        svgName: 'tile-events',
      },
      {
        address: 'https://visitnj.org/new-jersey-trip-ideas/',
        titleKey: 'planYourTrip',
        to: {
          pathname: '/visit/external',
          search: '?id=planYourTrip',
        },
        svgName: 'tile-plan-your-trip',
      },
    ]}
    buttonColorStyle='background-color-sky-blue'
  />
);
