import React from 'react';
import ButtonGrid from '../../components/buttonGrid';

export default function Visit() {
  return (
    <div>
      <ButtonGrid
        links={[
          {
            titleKey: 'New Jersey',
            to: '../njVisit',
            svgName: 'tile-visit',
          },
          {
            titleKey: 'Elizabeth New Jersey',
            to: '../elizabethVisit',
            svgName: 'tile-visit-elizabet-icon',
          },
          {
           
            titleKey: 'Newark New Jersey',
            to: '../newarkVisit',
            svgName: 'tile-visit',
          },
        ]}
        buttonColorStyle='background-color-sky-blue'
      />

      
    </div>
  );
}
