import React from "react";
import ButtonGrid from '../components/buttonGrid';

export const NewarkVisit = () => (
  <ButtonGrid
    links={[
      {
        titleKey: 'Newark Visitors Guide',
        to: {
          pathname: '/newark/external',
          search: '?id=njNewarkVisitorGuide',
        },
        svgName: 'tile-things-to-do',
      },

    ]}
    buttonColorStyle='background-color-sky-blue'
  />
);
