import React from "react";
import ButtonGrid from '../components/buttonGrid';
import { COLORS } from '../constants/appConstants';

export const ElizabethVisit = () => (
  <ButtonGrid
    links={[
      {
        titleKey: 'Layover Survival Guide',
        to: {
          pathname: '/elizabeth/external',
          search: '?id=elizabeth_Layover',
        },
        svgName: 'tile-visit-elizabet-icon',
        svgFill: COLORS.WHITE,
      },
      {
        titleKey: 'Things to-do',
        to: {
          pathname: '/elizabeth/external',
          search: '?id=elizabeth_Things-to-do',
        },
        svgName: 'tile-plan-your-trip',
        svgFill: COLORS.WHITE,
      },
      {
        titleKey: 'Shopping',
        to: {
          pathname: '/elizabeth/external',
          search: '?id=elizabeth_shopping',
        },
        svgName: 'tile-shopping-bag-icon',
        svgFill: COLORS.WHITE,
      },
      {
        titleKey: 'Restaurant',
        to: {
          pathname: '/elizabeth/external',
          search: '?id=elizabeth_restaurants',
        },
        svgName: 'tile-shops-and-restaurants',
        svgFill: COLORS.WHITE,
      },
    ]}
    buttonColorStyle='background-color-sky-blue'
  />
);
