import { COLORS } from '../constants/appConstants';
import Home from '../pages/Home';
import Transit from '../pages/transit';
import Visit from '../pages/Visit';
import { Information } from '../pages/information';
import { ExternalPage } from '../pages/externalPage';
import rentalCarService from '../pages/rentalCarService';
import taxiService from '../pages/taxiService';
import carService from '../pages/carService';
import { LostFound } from '../pages/lostFound';
import { ElizabethVisit } from '../pages/elizabethVisit';
import { NJVisit } from '../pages/njVisit';
import { StayTile } from '../pages/stayTile';
import { NewarkVisit } from '../pages/newarkVisit';

/*
  config - contains react-router-dom properties
  nameKey - define i18n namekey or leave it blank if this is not visible in menus
  buttonGrid - TBD
  bottomTabBarImage - define SVG image name if it is part of bottom tab bar or leave it empty
  bottomTabBarQueryString? - optional query string to pass to route for button tab bar menu item
  showBottomTabBar - boolean flag if this page should show bottom tab bar
  colorTheme - define color theme for this page (used in menus)
  noScrollBar? - optional boolean to disable overflow y scroll bar (used mostly on page with iFrame)
*/
export const RouteItems = [
  {
    config: {
      component: Home,
      exact: true,
      path: '/',
    },
    nameKey: 'home',
    buttonGrid: false,
    bottomTabBarImage: 'bottom-tab-bar-home',
    showBottomTabBar: false,
    colorTheme: '#FFCD00',
  },
  {
    config: {
      component: Transit,
      exact: true,
      path: '/transit',
    },
    nameKey: 'transit',
    buttonGrid: true,
    bottomTabBarImage: 'bottom-tab-bar-transit',
    showBottomTabBar: true,
    colorTheme: COLORS.GREEN,
  },
  {
    config: {
      component: StayTile,
      exact: true,
      path: '/stayTile',
    },
    nameKey: 'stay',
    buttonGrid: true,
    bottomTabBarImage: 'bottom-tab-bar-stay',
    bottomTabBarQueryString: '?id=stay',
    showBottomTabBar: true,
    colorTheme: COLORS.RED,
    noScrollBar: true,
  },
  {
    config: {
      component: Visit,
      exact: true,
      path: '/visit',
    },
    nameKey: 'visit',
    buttonGrid: true,
    bottomTabBarImage: 'bottom-tab-bar-visit',
    showBottomTabBar: true,
    colorTheme: COLORS.SKY_BLUE,
  },
  {
    config: {
      component: ExternalPage,
      exact: true,
      path: '/visit/external',
    },
    nameKey: '',
    buttonGrid: true,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.SKY_BLUE,
  },
  {
    config: {
      component: Information,
      exact: true,
      path: '/information',
    },
    nameKey: 'information',
    buttonGrid: true,
    bottomTabBarImage: 'bottom-tab-bar-info',
    showBottomTabBar: true,
    colorTheme: COLORS.YELLOW,
  },
  {
    config: {
      component: LostFound,
      exact: true,
      path: '/information/lost-found',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.YELLOW,
  },
  {
    config: {
      component: ExternalPage,
      exact: true,
      path: '/information/external',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.YELLOW,
    noScrollBar: true, // use iframe scrollbar instead
  },
  {
    config: {
      component: ExternalPage,
      exact: true,
      path: '/airport',
    },
    nameKey: 'airport',
    buttonGrid: true,
    bottomTabBarImage: 'bottom-tab-bar-airport',
    bottomTabBarQueryString: '?id=ewrAirport',
    showBottomTabBar: true,
    colorTheme: COLORS.ORANGE,
    noScrollBar: true,
  },
  {
    config: {
      component: ExternalPage,
      exact: true,
      path: '/external',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.YELLOW,
    noScrollBar: true,
  },
  {
    config: {
      component: ExternalPage,
      exact: true,
      path: '/transit/external',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.GREEN,
    noScrollBar: true,
  },
  {
    config: {
      component: rentalCarService,
      exact: true,
      path: '/transit/rental-car-service',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.GREEN,
  },
  {
    config: {
      component: taxiService,
      exact: true,
      path: '/transit/taxi-service',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.GREEN,
  },
  {
    config: {
      component: carService,
      exact: true,
      path: '/transit/car-service',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.GREEN,
  },
  {
    config: {
      component: ElizabethVisit,
      exact: true,
      path: '/elizabethVisit',
    },
    nameKey: '',
    buttonGrid: true,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.SKY_BLUE,
  },
  {
    config: {
      component: NJVisit,
      exact: true,
      path: '/njVisit',
    },
    nameKey: '',
    buttonGrid: true,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.SKY_BLUE,
  },
  {
    config: {
      component: StayTile,
      exact: true,
      path: '/stayTile',
    },
    nameKey: '',
    buttonGrid: true,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.SKY_RED,
  },
  {
    config: {
      component: NewarkVisit,
      exact: true,
      path: '/newarkVisit',
    },
    nameKey: '',
    buttonGrid: true,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.SKY_BLUE,
  },
  {
    config: {
      component: ExternalPage,
      exact: true,
      path: '/newark/external',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.SKY_BLUE,
    noScrollBar: true,
  },
  {
    config: {
      component: ExternalPage,
      exact: true,
      path: '/elizabeth/external',
    },
    nameKey: '',
    buttonGrid: false,
    bottomTabBarImage: '',
    showBottomTabBar: true,
    colorTheme: COLORS.SKY_BLUE,
    noScrollBar: true,
  },
];
